<template>
  <div class="data-yield">
    <div class="view1 art1" v-waypoint="{active: !aniMap.show, callback: (e) => cb(e, 0),  options}">
      <b-container>
        <transition enter-active-class="animated delay-500 fadeInUp">
          <div class="text-center tit" v-show="aniMap.show">
            <b-img :src="require('../../assets/images/yield/t2.png')"/>
          </div>
        </transition>
        <transition enter-active-class="animated delay-500 fadeInUp">
          <div class="sub-text1" v-show="aniMap.show">Discover the best yield farms. Feeds are collected from major yield farms and update every 10 minutes.</div>
        </transition>
        <transition enter-active-class="animated delay-500 fadeInUp">
          <b-row class="no-gutters" v-show="aniMap.show">
            <b-col cols="12">
              <b-button-group class="tabs">
                <b-button @click="onTab(1)" :class="{active: idx === 1}" class="tab" pill>All</b-button>
                <b-button @click="onTab(2)" :class="{active: idx === 2}" class="tab" pill>Fiat</b-button>
                <b-button @click="onTab(3)" :class="{active: idx === 3}" class="tab" pill>BNB</b-button>
                <b-button @click="onTab(4)" :class="{active: idx === 4}" class="tab" pill>CAKE</b-button>
                <b-button @click="onTab(5)" :class="{active: idx === 5}" class="tab" pill>BTC</b-button>
              </b-button-group>
              <div class="tab-items">
                <div class="farm-table d-none d-md-block">
                  <div class="tr thead">
                    <div class="td c1">Pools</div>
                    <div class="td c2">Earn</div>
                    <div class="td c3">APR</div>
                    <div class="td c4">Farm</div>
                    <div class="td c5"></div>
                  </div>
                  <div class="tr" v-for="(f,index) in currentList" v-bind:key="index">
                    <div class="c1 td">
                      <div class="img-box">
                        <b-img width="40" height="40" :src="require('../../assets/images/coins/' + f.name.split('-')[0] + '.png')" />
                        <b-img class="icon" v-if="f.name.split('-').length === 2" :src="require('../../assets/images/coins/' + f.name.split('-')[1] + '.png')" />
                      </div>
                      <span class="t0">{{f.name}}</span>
                    </div>
                    <div class="c2 td">
                      <div class="img-box">
                        <b-img width="40" height="40" :src="require('../../assets/images/yield/' + f.farm + '.png')"/>
                      </div>
                      <span class="t1">{{f.farm | toToken}}</span>
                    </div>
                    <div class="c3 td">
                      <div class="t2">{{Number(f.apr).toFixed(2)}}%</div>
                    </div>
                    <div class="c4 td">
                      <div class="t3">{{f.farm | toFarm}}</div>
                    </div>
                    <div class="c5 td">
                      <b-button pill class="btn" @click="goFarm(f)">Go Farming ></b-button>
                    </div>
                  <!-- </div>
                  <div class="tr">
                    <div class="more pointer">
                      <span>
                        Load More
                      </span>
                      <b-img width="20" height="20" :src="require('../../assets/images/more.png')"/>
                    </div> -->
                  </div>
                </div>
                <div class="farm-list d-md-none">
                  <div class="farm-list-item" v-for="(f,index) in currentList" v-bind:key="index">
                    <div class="item1">
                      <div class="i1">
                        <div class="img-box">
                          <b-img width="30" height="30" :src="require('../../assets/images/coins/' + f.name.split('-')[0] + '.png')" />
                          <b-img class="icon" v-if="f.name.split('-').length === 2" :src="require('../../assets/images/coins/' + f.name.split('-')[1] + '.png')" />
                        </div>
                        <div class="txt">{{f.name}}</div>
                      </div>
                      <div class="i2">
                        <div class="img-box">
                          <b-img width="30" height="30" :src="require('../../assets/images/yield/' + f.farm + '.png')"/>
                        </div>
                        <div class="txt">{{f.farm | toToken}}</div>
                      </div>
                      <div class="i3">
                        <div class="t1">APR</div>
                        <div class="t2">{{Number(f.apr).toFixed(2)}}%</div>
                      </div>
                    </div>
                    <div class="item2">
                      <div class="txt">START FARMING</div>
                      <b-button pill @click="goFarm(f)">GO FARMING ></b-button>
                    </div>
                  </div>

                  <!-- <div class="more pointer">
                      <span>
                        Load More
                      </span>
                    <b-img width="20" height="20" :src="require('../../assets/images/more.png')"/>
                  </div> -->
                </div>
              </div>
            </b-col>
          </b-row>
        </transition>
      </b-container>
    </div>
  </div>
</template>

<script>
const MAP = {
  'acryptos': {name: 'ACryptoS', img: 'i1.png', url: 'https://app.acryptos.com/', token: 'ACS'},
  'alpaca': {name: 'Alpaca', img: 'ALPACA.png', url: 'https://app.alpacafinance.org/farm', token: 'ALPACA'},
  'autofarm': {name: 'Autofarm', img: 'AUTO.png', url: 'https://autofarm.network/', token: 'AUTO'},
  'beefy': {name: 'Beefy', img: 'ALPACA.png', url: 'https://app.beefy.finance/', token: 'BIFI'},
  'bunny': {name: 'Bunny', img: 'i1.png', url: 'https://pancakebunny.finance/pool', token: 'BUNNY'},
  'mdex': {name: 'MDEX', img: 'MDX.webp', url: 'https://mdex.com/#/liquidity', token: 'MDX'},
  'pancake': {name: 'PancakeSwap', img: 'CAKE.png', url: 'https://pancakeswap.finance/farms', token: 'CAKE'},
};


const FILTERS = [
  [''],
  [''],
  ['BUSD', 'USDT'],
  ['BNB'],
  ['CAKE'],
  ['BTC']
];


import axios from 'axios';

export default {
  name: "yield",
  data: () => ({
    options: {
      root: null,
      rootMargin: '0px 0px 0px 0px',
      threshold: [0, 1]
    },
    aniMap: {
      show: false,
      show1: false,
    },
    idx: 1,

    list: [],
    offset: 0,
    filterText: ['']
  }),
  methods: {
    cb({going, direction}, idx) {
      if(direction === 'bottom') return;
      if(this.$waypointMap.GOING_IN === going) {
        this.aniMap[`show${idx?idx:''}`] = true
      } else {
        this.aniMap[`show${idx?idx:''}`] = false
      }
    },
    onTab(idx) {
      this.idx = idx
      this.filterText = FILTERS[idx];
    },
    getImg(f) {
      return MAP[f.farm].img;
    },
    goFarm(f) {
      let url = MAP[f.farm].url;
      window.open(url, '_blank');
    }
  },
  computed: {
    currentList() {
      // return this.list.slice(this.offset*LIMIT, (this.offset + 1)*LIMIT);
      let self = this;
      return this.list.filter(function(item) {
        for (let i = 0; i < self.filterText.length; ++i) {
          if (item.name.indexOf(self.filterText[i]) !== -1) return true;
        }
        return false;
      })
    }
  },
  async created() {
    try {
      let ret = await axios.get('https://yield.berrydata.co/batch');
      this.list = ret.data;
    } catch (e) {
        console.dir(e);
    }
  },
  filters: {
    toFarm(name) {
      return MAP[name].name
    },
    toUrl(name) {
      return MAP[name].url
    },
    toToken(name) {
      return MAP[name].token
    },
    toImg(f) {
      return MAP[f.farm].img;
    }
  }
}
</script>

<style lang="less">
@color0: #3C103A;
@color1: #880D85;
@color2: #40153E;
@color3: #C695BC;
@color4: #f1e7f0;
  .data-yield {
    background: #FFFAFF;
    overflow: hidden;
    .view1 {
      padding-top: 50px;
      padding-bottom: 76px;
      .sub-text1 {
        text-align: center;
        line-height: 22px;
        font-size: 14px;
        color: #3C103A;
        margin-top: 10px;
        margin-bottom: 30px;
      }
    }
    .tabs {
      margin-bottom: 20px;
      .tab {
        width: 100px;
        height: 47px;
        font-size: 20px;
        background-color: #F7F2F6;
        border: none;
        color: #3C103A;
        margin-right: 10px;
        &.active {
          background-color: #4B9629;
          color: #fff;
        }
      }
    }

    .farm-table {
      box-shadow: 0 0 10px @color3;
      border-radius: 14px;
      position: relative;
      overflow: hidden;
      &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 5px;
        border-radius: 14px;
        background-color: @color1;
      }
      .tr {
        display: flex;
        flex-wrap: wrap;
        border-bottom: 1px solid @color3;
        align-items: center;
        margin-right: 50px;
        margin-left: 50px;
        padding: 16px 0;
        &:last-of-type {
          border-bottom: none;
        }
      }
      .td {
        display: inline-flex;
        align-items: center;
        font-size: 10px;
        * {
          font-weight: bold;
          font-size: 15px;
        }
      }
      .thead {
        margin: 0;
        padding: 0;
        padding-left: 65px;
        padding-right: 65px;
        height: 80px;
        background-color: @color4;
        .td {
          font-size: 20px;
          font-weight: bold;
        }
      }
      .img-box {
        position: relative;
        .icon {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          position: absolute;
          bottom: 0;
          right: -10px;
          background-color: #eee;
        }
      }
      .c1 { flex: 0 0  27%;}
      .c2 { flex: 0 0  20%;}
      .c3 { flex: 0 0  17%;}
      .c4 { flex: 0 0  18%;}
      .c5 { flex: 0 0  18%;}
      .c3, .c4 {
        justify-content: center;
      }
      .c5 {
        justify-content: flex-end;
      }
      .t0 {
        margin-left: 15px;
      }
      .t1 {
        margin-left: 10px;
      }
      .t0, .t1, .t3 {
        color: @color0;
      }
      .t2 {
        color: @color1;
      }
      .t3 {
        font-size: 18px;
      }
      .btn {
        width: 175px;
        height: 62px;
        background-color: @color1;
        font-size: 18px;
        font-weight: normal;
        border: none;
      }
      .more {
        margin: 0 auto;
        display: inline-flex;
        align-items: center;
        span {
          font-size: 20px;
          line-height: 33px;
          margin: 0 auto;
          color: #880D85;
          &:hover {
            text-decoration: underline;
          }
        }
        img {
          margin-left: 5px;
        }
      }
    }

  }
@media (max-width: 768px) {
  .data-yield {
    .tit {
      img {
        width: 100%;
      }
    }
    .view1 {
      padding: 45px 10px;
      .sub-text1 {
        font-size: 12px;
        line-height: 17px;
        width: 167px;
        margin: 7px auto;
      }
    }
    .tabs {
      margin-top: 25px;
      .tab {
        width: 63px;
        height: 29px;
        font-size: 13px;
        margin-right: 6px;
      }
    }
    .farm-list {
      .more {
        font-size: 20px;
        font-weight: bold;
        line-height: 33px;
        margin-top: 25px;
        text-align: center;
        span {
          color: #880D85;
          margin-right: 5px;
        }
      }
    }
    .farm-list-item {
      box-shadow: 0 0 10px #c695bc;
      border-radius: 14px;
      position: relative;
      overflow: hidden;
      //padding: 0 15px;
      margin-bottom: 20px;
      &:last-of-type {
        margin-bottom: 0;
      }
      &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        height: 5px;
        border-radius: 14px;
        background-color: #880D85;
      }
      .item1, .item2 {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: bold;
      }
      .item1 {
        line-height: 18px;
        padding: 20px 15px 10px;
        .i1, .i2 {display: inline-flex; align-items: center}
        .i2 {
          margin-left: 20px;
        }
        .i3 {
          margin-left: auto;
          text-align: center;
          .t2 {
            color: #880D85;
          }
        }
        .txt {
          margin-left: 4px;
        }
        .img-box {
          position: relative;
          .icon {
            width: 15px;
            height: 15px;
            right: -5px;
            position: absolute;
            bottom: 0;
            border-radius: 50%;
            background-color: #778CA0;
          }
        }
      }
      .item2 {
        padding: 15px;
        background-color: #f1e7f0;
        justify-content: space-between;
        color: #3C103A;
        .btn {
          width: 115px;
          height: 32px;
          background-color: #880D85;
          border: none;
          font-size: 12px;
        }
      }
    }

  }
}
</style>